import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, TopMenu, MenuRow, NavItem, NavMenu, Logo } from 're-cy-cle';
import { MinimalColoredTextButton } from '@code-yellow/spider';
import { Header, Modal, Icon } from 'semantic-ui-react';
import { Route, withRouter } from 'react-router-dom';
import ImgLogo from 'image/logo.png';
import { SmallAvatar } from 'component/UserAvatar';
import { BUILD_INFO } from 'helpers';
import { hasLowLevelPermission } from '@code-yellow/spider';
import { ModuleRepository } from '_iae/module/repository';
import { userInGroup } from 'helpers/currentUser';
import { theme } from 'styles';

const MyLogo = () => (
  <Logo>
    <img src={ImgLogo} height="35" alt="logo" />
  </Logo>
);

@withRouter
@observer
export default class AppHeader extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired,
  };

  @observable debug = false;
  @observable showLogoutModal = false;

  toggleDebug = () => {
    this.debug = !this.debug;

    if (this.debug) {
      localStorage.setItem('debug', true);
    } else {
      localStorage.removeItem('debug');
    }
  }

  constructor(...args) {
    super(...args);
    this.debug = !!localStorage.getItem('debug');
  }

  hasPermission = perms => {
    return this.props.store.currentUser.hasPermission(perms);
  };

  renderAssets = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.assets.users')}
          to="/assets/user/overview"
          activePath="/assets/user/"
        />
        <NavItem
          title={t('nav.assets.globalValues')}
          to="/assets/global-value/overview"
          activePath="/assets/global-value/"
        />
        <NavItem
          title={t('nav.assets.globalFiles')}
          to="/assets/global-file/overview"
          activePath="/assets/global-file/"
        />
        <NavItem
          title={t('nav.assets.emailTemplates')}
          to="/assets/email-template/overview"
          activePath="/assets/email-template/"
        />
      </NavMenu>
    );
  };

  renderAddDeviceButton = () => {
    return (
      <>
        {hasLowLevelPermission('device.add_device') && (
          <MinimalColoredTextButton data-cy="header-add-device-button" text={t('device.createButton')} onClick={() => this.props.history.push('/operations/device/add')} customColor={theme.primaryColor} />
        )}
      </>

    );
  };

  renderOperations = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.operations.devices')}
          to="/operations/device/overview"
          activePath="/operations/device/"
        />
        {userInGroup(['admin', 'operator']) && (
          <NavItem
            title={t('nav.operations.rainBarrel')}
            to="/operations/rain-barrel/overview"
            activePath="/operations/rain-barrel/"
          />)}
        {userInGroup(['admin']) && (
          <>
            <NavItem
              title={t('nav.operations.firmwareVersion')}
              to="/operations/firmware/overview"
              activePath="/operations/firmware/"
            />
            <NavItem
              title={t('nav.operations.hardwareVersion')}
              to="/operations/hardware/overview"
              activePath="/operations/hardware/"
            />

            <NavItem
              title={t('nav.operations.batch')}
              to="/operations/batch/overview"
              activePath="/operations/batch/"
            />
          </>
        )}
      </NavMenu>
    );
  };

  renderKpn = () => {
    return (
      <NavMenu>
        {userInGroup(['admin']) && (
          <>
            <NavItem
              title={t('nav.kpn.uplink')}
              to="/kpn/uplink/overview"
              activePath="/kpn/uplink/"
            />

            <NavItem
              title={t('nav.kpn.downlink')}
              to="/kpn/downlink/overview"
              activePath="/kpn/downlink/"
            />
          </>
        )}
      </NavMenu>
    );
  };

  renderTelemetry = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.telemetry.lppRecord')}
          to="/telemetry/lpp-record/overview"
          activePath="/telemetry/lpp-record/"
        />
      </NavMenu>
    );
  };

  renderAudit = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.audit.accessLog')}
          to="/audit/access-log/overview"
          activePath="/audit/access-log/"
        />
      </NavMenu>
    );
  }

  renderAccountMenu = () => {
    const { store } = this.props;
    const { version, branch } = BUILD_INFO;

    return (
      <NavItem
        title={
          <span data-test-user-details>
            <SmallAvatar user={store.currentUser} />
            {' '}
            {store.currentUser.fullName} (
            {branch && branch !== 'production' && branch + ' '}
            {version}
            )
          </span>
        }
        to="/account/details"
        activePath="/account/"
      />
    );
  };

  renderAccount = () => {
    return (
      <NavMenu>
        <NavItem
          title={t('nav.account.account')}
          to="/account/details"
        />
        <React.Fragment>
          <NavItem title={t('nav.account.changelog')} to="/account/changelog" />
        </React.Fragment>
      </NavMenu>
    );
  };

  render() {
    const { currentUser } = this.props.store;
    const { moduleRepository } = this.props;
    if (!this.props.store.isAuthenticated || (
      currentUser.groupNames === undefined &&
      !currentUser.isSuperuser
    )) {
      return (
        <TopMenu>
          <MenuRow>
            <MyLogo />
          </MenuRow>
          <MenuRow />
        </TopMenu>
      );
    }

    const logoutModal = (
      <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
        size='small'>
        <Header icon='archive' content={t('user.account.logoutButton')} />
        <Modal.Content>
          <p>
            {t('user.account.logout.confirm')}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
            <Icon name='remove' /> {t('form.no')}
          </Button>
          <Button color='green' inverted onClick={() => {
            this.props.store.performLogout().then(() => {
              this.showLogoutModal = false;
            });
          }}>
            <Icon name='checkmark' /> {t('form.yes')}
          </Button>
        </Modal.Actions>
      </Modal>
    );

    let menu = currentUser.isSuperuser ? (
      <TopMenu>
        <MenuRow>
          <MyLogo />
          <NavMenu>
            <NavItem
              title={t('nav.assets.users')}
              to="/assets/user/overview"
              activePath="/assets/user/"
            />
            {moduleRepository.modules.map(module => {
              return module.navigationMenu?.()?.topMenuItem;
            })}
          </NavMenu>
          {this.renderAccountMenu()}
        </MenuRow>
        <MenuRow>
          <Route path="/account" render={this.renderAccount} />
          {/* Hackity hack hack to always show second menu bar */}
          <NavItem title=" " to="/" activePath="/neverusethispath" />
        </MenuRow>
      </TopMenu>
    ) : (
      <TopMenu>
        <MenuRow>
          <MyLogo />
          <NavMenu>
            <NavItem
              title={t('nav.main.operations')}
              to="/operations/device/overview"
              activePath="/operations/"
            />
            {userInGroup(['admin']) && (
              <NavItem
                title={t('nav.main.telemetry')}
                to="/telemetry/lpp-record/overview"
                activePath="/telemetry/"
              />)}

            {userInGroup(['admin']) && (
              <NavItem
                title={t('nav.main.kpn')}
                to="/kpn/uplink/overview"
                activePath="/kpn/"
              />)}
            {hasLowLevelPermission('assets.add_user') && (
              <NavItem
                title={t('nav.main.assets')}
                to="/assets/user/overview"
                activePath="/assets/"
              />)}
            {hasLowLevelPermission('audit.view_accesslogentry') && (
              <NavItem
                title={t('nav.audit.accessLog')}
                to="/audit/access-log/overview"
                activePath="/audit/access-log/"
              />)}
            {/* {moduleRepository.modules.map(module => {
              return module.navigationMenu().topMenuItem;
            })} */}
          </NavMenu>
          {this.renderAddDeviceButton()}
          {this.renderAccountMenu()}
        </MenuRow>
        <MenuRow>
          <Route path="/account" render={this.renderAccount} />
          <Route path="/assets/" render={this.renderAssets} />
          <Route path="/operations/" render={this.renderOperations} />
          <Route path="/telemetry/" render={this.renderTelemetry} />
          <Route path="/kpn/" render={this.renderKpn} />
          <Route path="/audit/" render={this.renderAudit} />
        </MenuRow>
      </TopMenu>
    );

    return (
      <React.Fragment>
        {menu}
        {logoutModal}
      </React.Fragment>
    );
  }
}
