export default {
  nav: {
    main: {
      assets: 'Master data',
      operations: 'Operations',
      kpn: 'KPN',
      telemetry: 'Telemetry',
    },
    telemetry: {
      lppRecord: 'LPP Records',
    },
    kpn: {
      kpnDevices: 'Devices',
      uplink: 'Uplinks',
      downlink: 'Downlinks',
    },
    operations: {
      devices: 'Devices',
      firmwareVersion: 'Firmware version',
      hardwareVersion: 'Hardware version',
      batch: 'RLC Batch',
      rainBarrel: 'Rain barrel',
    },
    assets: {
      users: 'Users',
      globalValues: 'Settings',
      globalFiles: 'Special files',
      emailTemplates: 'Email templates',
    },
    audit: {
      accessLog: 'Access Log',
    },
    account: {
      account: 'Account',
      changelog: 'Changelog'
    }
  },
  user: {
    overview: {
      title: 'Users',
      addButton: 'Add User',
    },
    create: {
      title: 'Create User',
    },
    edit: {
      title: 'Edit User',
    },
    field: {
      fullName: { label: 'Name' },
      firstName: { label: 'First Name' },
      lastName: { label: 'Last Name' },
      email: { label: 'Email' },
      language: { label: 'Language' },
      password: { label: 'Password' },
      dateJoined: { label: 'Added At' },
      groups: {
        label: 'Groups',
        value: {
          superuser: 'Superuser',
          admin: 'Admin',
          manager: 'Manager',
          operator: 'Operator',
          external_api: 'API',
          production: 'Production',
        },
        empty: 'No groups assigned',
      },
      isActive: {
        label: 'Users',
        options: {
          all: 'All',
          yes: 'Active',
          no: 'Not Active',
        }
      },
    },
    login: {
      title: 'Login',
      forgotPasswordLink: 'Forgot password?',
      errors: {
        invalidCredentials: 'Username or password do not match',
        unknown: 'Unknown error, status code: {{status}}',
      },
      loginButton: 'Log In',
    },
    passwordForgot: {
      title: 'Forgot password',
      requestButton: 'Send Email',
      requestedEmailText:
        'An email with a reset code has been sent to this email address.',
      loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
    },
    resetPassword: {
      title: 'Reset password',
      resetButton: 'Reset',
      saveSuccess: 'Password is successfully reset.',
      loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
    },
    changePassword: {
      title: 'Change Password',
      field: {
        passwordOld: {
          label: 'Current password',
        },
        passwordNew: {
          label: 'New password',
        },
      },
      saveButton: 'Save',
    },
    account: {
      title: 'Account Details',
      logoutButton: 'Log Out',
      info: {
        title: 'Account Info',
      },
      password: {
        title: 'Change Password',
      },
      logout: {
        title: 'Log Out',
        confirm: 'Are you sure you want to logout?'
      },
    },
  },
  myFilter: {
    custom: 'Custom',
    action: {
      unsetDefault: 'Unset Default',
      setDefault: 'Set Default',
      setCurrent: 'Save Current Filters',
      delete: 'Delete',
    },
  },
  filters: {
    deletedFilter: {
      label: 'Deleted',
      withoutDeleted: 'Without deleted',
      onlyDeleted: 'Only deleted',
      bothDeletedAndNot: 'both'
    },
    notesFilter: {
      label: 'Notes',
      withoutNotes: 'Without notes',
      onlyNotes: 'Only notes',
      bothNotesAndNot: 'both'
    }
  },
  globalValue: {
    edit: {
      title: 'Edit value of global setting',
    },
    overview: {
      title: 'Global settings',
    },
    field: {
      key: {
        label: 'Setting name',
      },
      value: {
        label: 'Value',
      },
      writable: {
        label: 'Writable?',
      },
      readable: {
        label: 'Readable?',
      },
    },
  },
  globalFile: {
    edit: {
      title: 'Edit special file',
    },
    overview: {
      title: 'Special files',
    },
    field: {
      key: {
        label: 'Name for file',
      },
      value: {
        label: 'File',
      },
      writable: {
        label: 'Writable?',
      },
      readable: {
        label: 'Readable?',
      },
    },
  },
  accessLogEntry: {
    overview: {
      title: 'Audit log',
    },
    field: {
      originalUser: {
        label: 'Original user',
      },
      masqueradingAsUser: {
        label: 'Masquerading as user',
      },
      ipAddress: {
        label: 'IP address',
      },
      method: {
        label: 'HTTP method',
      },
      endpoint: {
        label: 'Endpoint',
      },
      statusCode: {
        label: 'Status code',
      },
      additionalInfo: {
        label: 'Extra information',
      },
      requestTime: {
        label: 'Time of access',
      },
    },
  },
  tooltips: {
    masquerade: 'Masquerade',
    edit: 'Edit',
    delete: 'Delete',
    view: 'View',
    restore: 'Restore',
  },
  form: {
    actions: 'Actions',
    search: 'Search',
    repeat: 'Repeat',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Other',
      none: 'No file uploaded yet',
    },
    saveButton: 'Save',
    multiPick: {
      searchPlaceholder: 'Select…',
      selectedText: '$1 of $2 selected',
      noneSelectedText: 'None selected',
      selectAllButton: 'All',
      selectNoneButton: 'None',
    },
    notifications: {
      saveSuccess: 'Saved successfully',
      deleteSuccess: 'Deleted successfully',
      saveError: 'Error with saving ({{status}})',
      saveValError: 'Not all information is filled in correctly',
      saveAuthError: 'Invalid credentials',
      newAppVersion:
        'New version available, click to reload page as soon as possible',
    },
    submitButton: 'Submit',
    cancelButton: 'Cancel',
    sendButton: 'Send',
    addButton: 'Add',
    startDate: 'Start Date',
    endDate: 'End Date',
    deleteConfirmation: 'Are you sure you want to delete this entity?',
    restoreConfirmation: 'Are you sure you want to restore this entity?',
    no: 'No',
    yes: 'Yes',
    either: 'Either',
    failed: 'Failed',
    success: 'Success',
    warning: 'Warning',
    processing: 'Processing',
    hide: 'Hide',
    show: 'Show',
    instruction: 'Instruction',
    saveAndBackButton: 'Save and back',
    saveAndNewButton: 'Save and new',
  },
  crash: {
    heading: 'Application crashed',
    subHeading1: 'We’re very sorry, but the application has crashed.',
    subHeading2: 'Our team has been notified.',
    reload: 'Reload page'
  },
  emailTemplate: {
    overview: {
      title: 'Email templates',
      addButton: 'Add email template',
    },
    create: { title: 'Create mail template' },
    edit: { title: 'Edit mail template' },
    add: { title: 'Add mail template' },
    field: {
      id: { label: 'ID' },
      isDefault: { label: 'Is default' },
      name: { label: 'Name' },
      emailType: { label: 'Email type' },
      subject: { label: 'Subject' },
      textContent: { label: 'Text variant of email', placeholder: 'Some mail clients only support text' },
      senderEmail: { label: 'Sender email' },
    }
  },
  emailTypes: {
    password_reset: 'Password reset',
    user_activation: 'User activation',
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{weekNumber}}',
    },
    weekDay: {
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'Su',
    },
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
  },
  batch: {
    overview: {
      title: 'RLC batches',
      resultsTitle: 'RLC batches search results',
    },
    nav: {
      overview: 'RLC batches'
    },
    field: {
      id: { label: 'ID' },
      label: { label: 'Name' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
    },
    create: {
      title: 'Add RLC batch',
    },
    createButton: 'Add new RLC batch',
  },
  hardwareVersion: {
    overview: {
      title: 'Hardware versions',
      resultsTitle: 'Hardware version search results',
    },
    nav: {
      overview: 'Hardware versions'
    },
    field: {
      id: { label: 'ID' },
      version: { label: 'Version' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
    },
    create: {
      title: 'Add hardware version'
    },
    delete: {
      error: 'Could not delete hardware version',
      success: 'Hardware version removed'
    },
    edit: {
      pagesTitle: 'Pages',
      title: 'Edit hardware version'
    },
    createButton: 'Add new hardware version',
    editTooltip: 'Edit hardware version',
  },
  rainBarrel: {
    overview: {
      title: 'Rain barrels',
      resultsTitle: 'Rain barrels search results',
    },
    nav: {
      overview: 'Rain barrels'
    },
    field: {
      id: { label: 'ID' },
      name: { label: 'Name' },
      width: { label: 'Width (mm)' },
      height: { label: 'Height (mm)' },
      depth: { label: 'Depth (mm)' },
      volume: { label: 'Volume (m³)' },
      lowerBoundNormalization: { label: 'Lower bound pressure sensor' },
      upperBoundNormalization: { label: 'Upper bound pressure sensor' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
    },
    create: {
      title: 'Add rain barrel'
    },
    delete: {
      error: 'Could not delete rain barrel',
      success: 'Rain barrel removed'
    },
    edit: {
      pagesTitle: 'Pages',
      title: 'Edit rain barrel',
      normalizationInstructionLower: 'The lower bound value can be determined by filling the rain barrel with enough water such that the roof is sprayed when the pump is turned on. Keep the pump turned on until no more water is leaving the hoses. Turn the pump off. Wait for the RLC to transmit the pressure sensor data. The pressure sensor data at this point is the lower bound value.',
      normalizationInstructionUpper: 'The upper bound value can be determined by filling the rain barrel completely until water is leaving via the overflow port. Wait for the water to stop flowing out of the overflow port. Wait for the RLC to transmit the pressure sensor data. The pressure sensor data at this point is the upper bound value.',
    },
    createButton: 'Add new rain barrel',
    editTooltip: 'Edit rain barrel',
  },
  firmwareVersion: {
    overview: {
      title: 'Firmware versions',
      resultsTitle: 'Firmware versions search results',
    },
    nav: {
      overview: 'Firmware versions'
    },
    field: {
      id: { label: 'ID' },
      version: { label: 'Version' },
      link: { label: 'Link' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
    },
    create: {
      title: 'Add firmware version'
    },
    delete: {
      error: 'Could not delete firmware version',
      success: 'Firmware version removed'
    },
    edit: {
      pagesTitle: 'Pages',
      title: 'Edit firmware version'
    },
    createButton: 'Add new firmware version',
    editTooltip: 'Edit firmware version',
  },
  location: {
    overview: {
      title: 'Locations',
      resultsTitle: 'Locations search results',
    },
    nav: {
      overview: 'Locations'
    },
    field: {
      id: { label: 'ID' },
      identifier: { label: 'Identifier' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
    },
    create: {
      title: 'Add location'
    },
    delete: {
      error: 'Could not delete location',
      success: 'Location removed'
    },
    edit: {
      pagesTitle: 'Pages',
      title: 'Edit location'
    },
    createButton: 'Add location',
    editTooltip: 'Edit location',
  },
  device: {
    overview: {
      title: 'Devices',
      resultsTitle: 'Devices search results',
    },
    nav: {
      overview: 'Devices'
    },
    field: {
      id: { label: 'ID' },
      cohort: { label: 'Cohort' },
      devui: { label: 'Device UI' },
      appkey: { label: 'App key' },
      firmwareVersion: { label: 'FW version' },
      hardwareVersion: { label: 'HW version' },
      batch: { label: 'RLC Batch' },
      operationalStatus: { label: 'Operational status' },
      group: { label: 'Group' },
      rainBarrel: { label: 'Rain barrel' },
      location: { label: 'Location' },
      identifier: { label: 'Identifier' },
      createdAt: { label: 'Created at' },
      notes: { label: 'Notes' },
      shipped: { label: 'Shipped' },
      lastMessageAt: { label: 'Status' },
      selectionValveFlipped: { label: 'Flip selection valve orientation', explanation: 'This is used to flip the orientation of the selection valve. Check this box when the selection valve is installed in the wrong orientation.' },
    },
    create: {
      title: 'Add device'
    },
    delete: {
      error: 'Could not delete device',
      success: 'Device deleted'
    },
    edit: {
      pagesTitle: 'Pages',
      title: 'Edit page',
    },
    bulkActions: {
      error: {
        noRainBarrel: 'No rain barrel configured.',
        unknown: 'An unknown error occured',
        incorrectInformation: 'The information is not filled in correctly',
        processing: 'An error occurred while processing the bulk action',
      },
      modalTitles: {
        bulkControlPump: 'Bulk control pump',
        bulkControlDumpValve: 'Bulk control dump valve',
      },
      results: {
        successfullySent: 'Successfully sent',
        successfulDownlinks: 'Successful Downlinks',
        failedSent: 'Failed sending',
        failedDownlinks: 'Failed Downlinks',
      },
      chooseAction: 'Choose bulk action',
      changeOperationalStatus: 'Change operational status',
      changeGroup: 'Change group',
      changeFirmware: 'Change firmware version',
      changeHardware: 'Change hardware version',
      changeBatch: 'Change RLC batch',
      changeRainBarrel: 'Change rain barrel',
      controlPump: 'Control pump',
      controlValve: 'Control dump valve',
    },
    messageStatus: {
      none: 'No messages received yet',
      green: 'Last message less than 30 minutes ago',
      orange: 'Last message more than 30 minutes ago',
      red: 'Last message more than 3 hours ago',
    },
    createButton: 'Add device',
    editTooltip: 'Edit device',
  },
  uplink: {
    overview: {
      title: 'Uplinks',
      resultsTitle: 'Uplink search results'
    },
    field: {
      id: { label: 'ID' },
      payload: { label: 'Payload' },
      receivedAt: { label: 'Received at' },
      processedAt: { label: 'Processed at' },
    }
  },
  downlink: {
    overview: {
      title: 'Downlinks',
      resultsTitle: 'Downlink search results'
    },
    field: {
      id: { label: 'ID' },
      device: { label: 'Device ID' },
      payload: { label: 'Payload' },
      status: { label: 'Status' },
      failureReason: { label: 'Failure reason' },
      sentAt: { label: 'Sent at' },
      createdAt: { label: 'Created at' },

      pumpActivated: { label: 'Activate pump' },
      pumpDuration: { label: 'Duration in seconds (1 - 3600)' },
      pumpChoice: { label: 'Pump choice' },

      selectionValveActivated: { label: 'Activate selection valve' },
      selectionValveChoice: { label: 'Selection valve choice' },
      selectionValveFlipped: { label: 'Flipped orientation' },

      dumpValveDuration: { label: 'Open dump valve for x seconds' },
      dumpValveChoice: { label: 'Dump valve choice' },
      dumpValveActivated: { label: 'Activate dump valve' },

      scheduledActivated: { label: 'Schedule downlink' },
      scheduledAt: { label: 'Scheduled time' }
    },
    control: {
      pump: 'Control pump',
      dump_valve: 'Control dump valve',
      sendDownlink: 'Send downlink',
    },
    modalTitles: {
      sendDownlink: 'Send downlink to device',
      controlPump: 'Control pump of device',
      controlDump: 'Control dump valve of device',
    },
    tooltip: {
      errorCannotSendDownlink: 'Cannot send downlink at current time',
    },
    error: {
      warningNoRainBarrel: 'Some selected devices do not have rainbarrels configure. Downlinks will not be sent to these devices.'
    },
  },
  operatorView: {
    overview: {
      title: 'Operator view'
    },
    detailView: {
      title: 'Device',
      errorDevuiNotAvailable: 'Devui not yet available',
      copyToCsv: 'Copy to CSV',
      copyDevui: 'Copy DevUI AT command',
      copyAppkey: 'Copy App key AT command',
    },
    peripherals: {
      title: 'Peripherals',
      lastMessage: 'Last message',
      noMessage: 'No messages received yet!',
      window1: 'Window 1',
      window2: 'Window 2',
      pump: 'Pump',
      dumpValve: 'Dump Valve',
      selectionValve: 'Selection Valve',
      statusOpen: 'Open',
      statusClosed: 'Closed',
      statusOn: 'On',
      statusOff: 'Off',
      statusFront: 'Front',
      statusBack: 'Back',
    },
    moistureChart: {
      hours24: 'Last 24 hours',
      days7: 'Last 7 days',
      days14: 'Last 14 days',
      days30: 'Last 30 days',
      selectDateRange: 'Select date range',
      moistureSensor1: 'Moisture sensor 1',
      moistureSensor2: 'Moisture sensor 2',
      moistureSensor3: 'Moisture sensor 3',
      moistureSensor4: 'Moisture sensor 4',
      pressureSensor: 'Pressure sensor',
      indicatorWet: 'Wet',
      indicatorDry: 'Dry',
    },
    tooltip: {
      loadingDevui: 'Loading because the Devui has not been retrieved from KPN yet',
      loadingAppkey: 'Loading because the App key has not been retrieved from KPN yet',
    }
  },
  pageNotFoundMessage: 'Page not found',
  brandingMessage: 'Powered by Theta Vision',
  lppRecord: {
    overview: {
      title: 'LPP Records',
      resultsTitle: 'LPP records search results'
    },
    field: {
      id: { label: 'ID' },
      createdAt: { label: 'Created at' },
      channel: { label: 'Channel' },
      value: { label: 'Value' },
      dataType: { label: 'Data type' },
      device: { label: 'Device ID' },
      uplink: { label: 'Uplink ID' },
    }
  },
  homeDashboard: {
    shipped: 'Shipped RLCs',
    stocked: 'Stocked RLCs',
    installed: 'Installed RLCs',
  },
};
