export default {
  nav: {
    main: {
      assets: 'Stamgegevens',
      operations: 'Operatie',
      kpn: 'KPN',
      telemetry: 'Telemetrie',
    },
    telemetry: {
      lppRecord: 'Lpp Records',
    },
    kpn: {
      kpnDevices: 'Apparaten',
      uplink: 'Uplinks',
      downlink: 'Downlinks',
    },
    operations: {
      devices: 'Apparaten',
      firmwareVersion: 'Firmware versie',
      hardwareVersion: 'Hardware versie',
      batch: 'RLC oplage',
      rainBarrel: 'Regenton',
    },
    assets: {
      users: 'Gebruikers',
      globalValues: 'Instellingen',
      globalFiles: 'Bijzondere bestanden',
      emailTemplates: 'Email sjablonen',
    },
    audit: {
      accessLog: 'Toegangslog',
    },
    account: {
      account: 'Account',
      changelog: 'Changelog'
    }
  },
  user: {
    overview: {
      title: 'Gebruikers',
      addButton: 'Nieuwe gebruiker',
    },
    create: {
      title: 'Gebruiker aanmaken',
    },
    edit: {
      title: 'Gebruiker aanpassen',
    },
    field: {
      fullName: { label: 'Naam' },
      firstName: { label: 'Voornaam' },
      lastName: { label: 'Achternaam' },
      email: { label: 'E-mail' },
      language: { label: 'Taal' },
      password: { label: 'Wachtwoord' },
      dateJoined: { label: 'Toegevoegd Op' },
      groups: {
        label: 'Groepen',
        value: {
          superuser: 'Supergebruiker',
          admin: 'Administrator',
          manager: 'Manager',
          operator: 'Operator',
          external_api: 'API',
          production: 'Productie',
        },
        empty: 'Geen groepen toegekend',
      },
      isActive: {
        label: 'Users',
        options: {
          all: 'All',
          yes: 'Active',
          no: 'Not Active',
        }
      },
    },
    login: {
      title: 'Inloggen',
      forgotPasswordLink: 'Wachtwoord vergeten?',
      errors: {
        invalidCredentials: 'E-mail of wachtwoord is niet correct',
        unknown: 'Onbekende fout, status code: {{status}}',
      },
      loginButton: 'Inloggen',
    },
    passwordForgot: {
      title: 'Wachtwoord Vergeten',
      requestButton: 'Verstuur Email',
      requestedEmailText:
        'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
      loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
    },
    resetPassword: {
      title: 'Wachtwoord Reset',
      resetButton: 'Reset',
      saveSuccess: 'Wachtwoord is succesvol gereset',
      loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
    },
    changePassword: {
      title: 'Wachtwoord Aanpassen',
      field: {
        passwordOld: {
          label: 'Huidig wachtwoord',
        },
        passwordNew: {
          label: 'Nieuw wachtwoord',
        },
      },
      saveButton: 'Opslaan',
    },
    account: {
      title: 'Profiel',
      logoutButton: 'Uitloggen',
      info: {
        title: 'Gegevens',
      },
      password: {
        title: 'Wachtwoord Veranderen',
      },
      logout: {
        title: 'Uitloggen',
        confirm: 'Weet u zeker dat u wilt uitloggen?'
      },
    },
  },
  myFilter: {
    custom: 'Nieuw',
    action: {
      unsetDefault: 'Gebruik niet als standaard',
      setDefault: 'Gebruik als standaard',
      setCurrent: 'Sla huidige filters op',
      delete: 'Verwijderen',
    },
  },
  filters: {
    deletedFilter: {
      label: 'Verwijderd',
      withoutDeleted: 'Zonder verwijderd',
      onlyDeleted: 'Alleen verwijderd',
      bothDeletedAndNot: 'Beide'
    },
    notesFilter: {
      label: 'Notities',
      withoutNotes: 'Zonder notities',
      onlyNotes: 'Alleen notities',
      bothNotesAndNot: 'Beide'
    }
  },
  globalValue: {
    edit: {
      title: 'Waarde wijzigen van algemene instelling',
    },
    overview: {
      title: 'Algemene instellingen',
    },
    field: {
      key: {
        label: 'Naam instelling',
      },
      value: {
        label: 'Waarde',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  globalFile: {
    edit: {
      title: 'Bijzonder bestand bewerken',
    },
    overview: {
      title: 'Bijzondere bestanden',
    },
    field: {
      key: {
        label: 'Naam voor bestand',
      },
      value: {
        label: 'Bestand',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  accessLogEntry: {
    overview: {
      title: 'Auditlog',
    },
    field: {
      originalUser: {
        label: 'Oorspronkelijke gebruiker',
      },
      masqueradingAsUser: {
        label: 'Gemaskeerd als gebruiker',
      },
      ipAddress: {
        label: 'IP-adres',
      },
      method: {
        label: 'HTTP-methode',
      },
      endpoint: {
        label: 'Endpoint',
      },
      statusCode: {
        label: 'Statuscode',
      },
      additionalInfo: {
        label: 'Extra informatie',
      },
      requestTime: {
        label: 'Tijdstip van toegang',
      },
    },
  },
  tooltips: {
    masquerade: 'Voordoen als',
    edit: 'Aanpassen',
    delete: 'Verwijderen',
    view: 'Bekijk',
    restore: 'Herstel',
  },
  form: {
    search: 'Zoeken',
    actions: 'Acties',
    repeat: 'Herhaal',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Anders',
      none: 'Nog geen bestand geüpload',
    },
    saveButton: 'Opslaan',
    multiPick: {
      searchPlaceholder: 'Selecteer…',
      selectedText: '$1 uit $2 geselecteerd',
      noneSelectedText: 'Geen geselecteerd',
      selectAllButton: 'Alle',
      selectNoneButton: 'Geen',
    },
    notifications: {
      saveSuccess: 'Succesvol opgeslagen',
      deleteSuccess: 'Succesvol verwijderd',
      saveError: 'Fout met opslaan ({{status}})',
      saveValError: 'Niet alle informatie is correct ingevuld',
      saveAuthError: 'Niet geauthorizeerd',
      newAppVersion:
        'Nieuwe versie beschikbaar, klik om de pagina te verversen',
    },
    submitButton: 'Verstuur',
    cancelButton: 'Annuleren',
    sendButton: 'Verstuur',
    addButton: 'Toevoegen',
    deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
    restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
    startDate: 'Start Datum',
    endDate: 'Eind Datum',
    no: 'Nee',
    yes: 'Ja',
    either: 'Beide',
    failed: 'Gefaald',
    success: 'Geslaagd',
    warning: 'Let op',
    processing: 'Bezig met verwerken',
    hide: 'Verstop',
    show: 'Toon',
    instruction: 'Instructie',
    saveAndBackButton: 'Opslaan en terug',
    saveAndNewButton: 'Opslaan en nieuw',
  },
  crash: {
    heading: 'Applicatie gecrasht',
    subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
    subHeading2: 'Ons team is op de hoogte gesteld.',
    reload: 'Herlaad pagina'
  },
  emailTemplate: {
    overview: {
      title: 'Email sjablonen',
      addButton: 'Nieuw sjabloon',
    },
    create: { title: 'Mail sjabloon aanmaken' },
    edit: { title: 'Mail sjabloon aanpassen' },
    add: { title: 'Mail sjabloon toevoegen' },
    field: {
      id: { label: 'ID' },
      isDefault: { label: 'Is standaard' },
      name: { label: 'Naam' },
      emailType: { label: 'Email type' },
      subject: { label: 'Onderwerp' },
      textContent: { label: 'Text variant van email', placeholder: 'Sommige mail clients supporten alleen tekst' },
      senderEmail: { label: 'Verzender' },
    }
  },
  emailTypes: {
    password_reset: 'Wachtwoord reset',
    user_activation: 'Account activatie',
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{weekNumber}}',
    },
    weekDay: {
      monday: 'Ma',
      tuesday: 'Di',
      wednesday: 'Wo',
      thursday: 'Do',
      friday: 'Vr',
      saturday: 'Za',
      sunday: 'Zo',
    },
    month: {
      january: 'Januari',
      february: 'Februari',
      march: 'Maart',
      april: 'April',
      may: 'Mei',
      june: 'Juni',
      july: 'Juli',
      august: 'Augustus',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
  },
  batch: {
    overview: {
      title: 'RLC oplage',
      resultsTitle: 'RLC oplage zoekresultaten',
    },
    nav: {
      overview: 'RLC oplage'
    },
    field: {
      id: { label: 'ID' },
      label: { label: 'Naam' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    create: {
      title: 'Voeg RLC oplage toe',
    },
    createButton: 'Voeg nieuwe RLC oplage toe',
  },
  hardwareVersion: {
    overview: {
      title: 'Hardware versies',
      resultsTitle: 'Hardware versies zoekresultaten',
    },
    nav: {
      overview: 'Hardware versies'
    },
    field: {
      id: { label: 'ID' },
      version: { label: 'Versie' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    create: {
      title: 'Voeg hardware versie toe'
    },
    delete: {
      error: 'Kon hardware versie niet weghalen',
      success: 'Hardware versie verwijderd'
    },
    edit: {
      pagesTitle: 'Paginas',
      title: 'Bewerk hardware versie'
    },
    createButton: 'Voeg nieuwe hardware versie toe',
    editTooltip: 'Bewerk',
  },
  rainBarrel: {
    overview: {
      title: 'Regentonnen',
      resultsTitle: 'Regentonnen zoekresultaten',
    },
    nav: {
      overview: 'Regentonnen'
    },
    field: {
      id: { label: 'ID' },
      name: { label: 'Naam' },
      width: { label: 'Breedte (mm)' },
      height: { label: 'Hoogte (mm)' },
      depth: { label: 'Diepte (mm)' },
      volume: { label: 'Volume (m³)' },
      lowerBoundNormalization: { label: 'Ondergrens druksensor' },
      upperBoundNormalization: { label: 'Bovengrens druksensor' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    create: {
      title: 'Regenton toevoegen'
    },
    delete: {
      error: 'Kon regenton niet verwijderen',
      success: 'Regenton verwijderd'
    },
    edit: {
      pagesTitle: 'Paginas',
      title: 'Regenton bewerken',
      normalizationInstructionLower: 'De ondergrens waarde kan bepaald worden door genoeg water in de regenton te doen zodat het dak gesproeid wordt wanneer de pomp aan staat. Laat de pomp aanstaan tot er geen water meer uit de sproeislang komt. Zet de pomp uit. Wacht tot de RLC een nieuw bericht heeft gestuurd met de druksensor waarde. De druksensor waarde van dit laatste bericht moet worden ingevuld als ondergrens.',
      normalizationInstructionUpper: 'De bovengrens waarde kan bepaald worden door de regenton volledig te vullen met water totdat het water uit de overloop loopt. Stop met vullen en wacht tot het water niet meer uit de overloop loopt. Wacht tot de RLC een nieuw bericht heeft gestuurd met de druksensor waarde. De druksensor waarde van dit laatste bericht moet worden ingevuld als ondergrens.',
    },
    createButton: 'Voeg nieuwe regenton toe',
    editTooltip: 'Regenton bewerken',
  },
  firmwareVersion: {
    overview: {
      title: 'Firmware versies',
      resultsTitle: 'Firmware versies zoekresultaten',
    },
    nav: {
      overview: 'Firmware versies'
    },
    field: {
      id: { label: 'ID' },
      version: { label: 'Versie' },
      link: { label: 'Link' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    create: {
      title: 'Voeg firmware versie toe'
    },
    delete: {
      error: 'Kon firmware versie niet verwijderen',
      success: 'Firmware versie verwijderd'
    },
    edit: {
      pagesTitle: 'Paginas',
      title: 'Bewerk firmware versie'
    },
    createButton: 'Voeg nieuwe firmware versie toe',
    editTooltip: 'Bewerk firmware versie',
  },
  location: {
    overview: {
      title: 'Locaties',
      resultsTitle: 'Locaties zoekresultaten',
    },
    nav: {
      overview: 'Locaties'
    },
    field: {
      id: { label: 'ID' },
      identifier: { label: 'Identifier' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    create: {
      title: 'Voeg locatie toe'
    },
    delete: {
      error: 'Kon locatie niet verwijderen ',
      success: 'Locatie verwijderd'
    },
    edit: {
      pagesTitle: 'Paginas',
      title: 'Bewerk locatie'
    },
    createButton: 'Voeg locatie toe',
    editTooltip: 'Bewerk locatie',
  },
  device: {
    overview: {
      title: 'Apparaten',
      resultsTitle: 'Apparaten zoekresultaten',
    },
    nav: {
      overview: 'Apparaten'
    },
    field: {
      id: { label: 'ID' },
      cohort: { label: 'Cohort' },
      devui: { label: 'Apparaat ID' },
      appkey: { label: 'Applicatie sleutel' },
      firmwareVersion: { label: 'FW versie' },
      hardwareVersion: { label: 'HW versie' },
      batch: { label: 'RLC oplage' },
      rainBarrel: { label: 'Regenton' },
      operationalStatus: { label: 'Operationele status' },
      group: { label: 'Groep' },
      location: { label: 'Locatie' },
      identifier: { label: 'Identifier' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
      shipped: { label: 'Geleverd' },
      lastMessageAt: { label: 'Status' },
      selectionValveFlipped: { label: 'Beregeningsklep orientatie wisselen', explanation: 'Gebruik als de klep verkeerd om is aangesloten.' },
    },
    create: {
      title: 'Voeg apparaat toe'
    },
    delete: {
      error: 'Kon apparaat niet verwijderen ',
      success: 'Apparaat verwijderd'
    },
    edit: {
      pagesTitle: 'Paginas',
      title: 'Bewerk apparaat',
    },
    bulkActions: {
      error: {
        noRainBarrel: 'Geen regenton geconfigureerd.',
        unknown: 'Een onbekende fout is opgetreden',
        incorrectInformation: 'De informatie is niet juist ingevuld',
        processing: 'Een fout is opgetreden bij het verwerken van de bulk actie',
      },
      modalTitles: {
        bulkControlPump: 'Bulk aansturen pomp',
        bulkControlDumpValve: 'Bulk aansturen stortklep',
      },
      results: {
        successfullySent: 'Verzenden geslaagd bij ',
        successfulDownlinks: 'Geslaagde Downlinks',
        failedSent: 'Verzenden gefaald bij ',
        failedDownlinks: 'Gefaalde Downlinks',
      },
      chooseAction: 'Selecteer bulk actie',
      changeOperationalStatus: 'Wijzig operationele status',
      changeGroup: 'Wijzig groep',
      changeFirmware: 'Wijzig firmware versie',
      changeHardware: ' Wijzig hardware versie',
      changeBatch: 'Wijzig RLC oplage',
      changeRainBarrel: 'Wijzig regenton',
      controlPump: 'Aansturen pomp',
      controlValve: 'Aansturen stortklep',
    },
    messageStatus: {
      none: 'Nog geen berichten ontvangen',
      green: 'Laatste bericht minder dan 30 minuten geleden',
      orange: 'Laatste bericht meer dan 30 minuten geleden',
      red: 'Laatste bericht meer dan 3 uur geleden',
    },
    createButton: 'Voeg apparaat toe',
    editTooltip: 'Bewerk apparaat',
  },
  uplink: {
    overview: {
      title: 'Uplinks',
      resultsTitle: 'Uplink zoekresultaten'
    },
    field: {
      id: { label: 'ID' },
      payload: { label: 'Payload' },
      receivedAt: { label: 'Ontvangen op' },
      processedAt: { label: 'Verwerkt op' },
    }
  },
  downlink: {
    overview: {
      title: 'Downlinks',
      resultsTitle: 'Downlink zoekresultaten',
    },
    field: {
      id: { label: 'ID' },
      device: { label: 'Apparaat ID' },
      payload: { label: 'Payload' },
      status: { label: 'Status' },
      failureReason: { label: 'Reden falen' },
      sentAt: { label: 'Verzonden op' },
      createdAt: { label: 'Aangemaakt op' },

      pumpActivated: { label: 'Pomp activeren' },
      pumpDuration: { label: 'Duur in seconden (1 - 3600)' },
      pumpChoice: { label: 'Pomp keuze' },

      selectionValveActivated: { label: 'Beregeningsklep activeren' },
      selectionValveChoice: { label: 'Beregeningsklep keuze' },
      selectionValveFlipped: { label: 'Beregeningsklep omgedraaid' },

      dumpValveDuration: { label: 'Open stort klep voor x seconden' },
      dumpValveChoice: { label: 'Stortklep keuze' },
      dumpValveActivated: { label: 'Stortklep activeren' },

      scheduledActivated: { label: 'Downlink inplannen' },
      scheduledAt: { label: 'Geplande tijd' }
    },
    control: {
      pump: 'Pomp aansturen',
      dump_valve: 'Stortklep aansturen',
      sendDownlink: 'Stuur downlink',
    },
    modalTitles: {
      sendDownlink: 'Stuur downlink naar apparaat',
      controlPump: 'Stuur pomp aan van apparaat',
      controlDump: 'Stuur stortklep aan van apparaat',
    },
    tooltip: {
      errorCannotSendDownlink: 'Sturen van downlinks momenteel niet beschikbaar',
    },
    error: {
      warningNoRainBarrel: 'Sommige geselecteerde apparaten hebben geen regenton geconfigureerd. Er zullen geen downlinks naar deze apparaten verzonden worden'
    },
  },
  operatorView: {
    overview: {
      title: 'Beheerders overzicht',
    },
    detailView: {
      title: 'Apparaat',
      errorDevuiNotAvailable: 'Apparaat ID nog niet beschikbaar',
      copyToCsv: 'Kopieer naar CSV',
      copyDevui: 'Kopieer apparaat ID AT commando',
      copyAppkey: 'Kopieer applicatie sleutel AT commando',
    },
    peripherals: {
      title: 'Sensoren en actuatoren',
      lastMessage: 'Laatste ontvangen bericht',
      noMessage: 'Nog geen berichten ontvangen!',
      window1: 'Raam 1',
      window2: 'Raam 2',
      pump: 'Pomp',
      dumpValve: 'Stortklep',
      selectionValve: 'Beregeningsklep',
      statusOpen: 'Open',
      statusClosed: 'Gesloten',
      statusOn: 'Aan',
      statusOff: 'Uit',
      statusFront: 'Voorzijde',
      statusBack: 'Achterzijde',
    },
    moistureChart: {
      hours24: 'Afgelopen 24 uur',
      days7: 'Afgelopen 7 dagen',
      days14: 'Afgelopen 14 dagen',
      days30: 'Afgelopen 30 dagen',
      selectDateRange: 'Selecteer aantal dagen',
      moistureSensor1: 'Vochtsensor 1',
      moistureSensor2: 'Vochtsensor 2',
      moistureSensor3: 'Vochtsensor 3',
      moistureSensor4: 'Vochtsensor 4',
      pressureSensor: 'Druksensor',
      indicatorWet: 'Nat',
      indicatorDry: 'Droog',
    },
    tooltip: {
      loadingDevui: 'Aan het wachten tot apparaat ID beschikbaar is via KPN',
      loadingAppkey: 'Aan het wachten tot applicatie sleutel beschikbaar is via KPN',
    }
  },
  pageNotFoundMessage: 'Pagina niet gevonden',
  brandingMessage: 'Powered by Theta Vision',
  lppRecord: {
    overview: {
      title: 'LPP Records',
      resultsTitle: 'LPP records zoekresultaten',
    },
    field: {
      id: { label: 'ID' },
      createdAt: { label: 'Aangemaakt op' },
      channel: { label: 'Kanaal' },
      value: { label: 'Waarde' },
      dataType: { label: 'Data Type' },
      device: { label: 'Apparaat ID' },
      uplink: { label: 'Uplink ID' },
    }
  },
  homeDashboard: {
    shipped: 'Verzonden RLCs',
    stocked: 'Voorraad RLCs',
    installed: 'Geïnstalleerde RLCs',
  },
};
